import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClarityModule } from '@clr/angular';

import { AngularFireModule } from '@angular/fire';
//import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LayoutComponent } from './layout/layout.component';
import { environment } from '../environments/environment';


//import { KatoomiServerModule, WindowService } from '@katoomi/katoomi-server';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { WINDOW_PROVIDERS } from 'libs/katoomi-server/src/lib/window.providers';

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    HttpClientModule,    
    ClarityModule,
//    KatoomiServerModule,
    AppRoutingModule
  ],
  exports: [],
//  providers:    [ WINDOW_PROVIDERS ],
  bootstrap: [AppComponent]
})
export class AppModule {}
