<h1>Welcome backend users</h1>
<table>
    <tr>
        <th>Nav</th>
    </tr>
    <tr>
        <td><router-outlet></router-outlet></td>
    </tr>
</table>

FOOTER